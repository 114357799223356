import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import { SplashScreen } from 'src/components/loading-screen';
import ConfirmRegisterPage from 'src/pages/auth/confirm-register';

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));
const AccountSigninPage = lazy(() => import('src/pages/auth/signin-account'));

// ----------------------------------------------------------------------

const authJwt = {
  path: 'jwt',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: <JwtLoginPage />,
    },
    {
      path: 'register',
      element: (
        <AuthClassicLayout title="Manage the franchise more effectively with DuckingA">
          <JwtRegisterPage />
        </AuthClassicLayout>
      ),
    },
  ],
};

const authConfirmation = {
  path: 'confirmation/email',
  element: (
    <AuthClassicLayout title="Manage the franchise more effectively with DuckingA">
      <ConfirmRegisterPage />
    </AuthClassicLayout>
  ),
};

export const accountAuth = {
  path: 'complete',
  element: (
    <GuestGuard>
      <AccountSigninPage />
    </GuestGuard>
  ),
};

export const authRoutes = [
  {
    path: 'auth',
    children: [authJwt, authConfirmation],
  },
  {
    path: 'sign-in',
    children: [accountAuth],
  },
];
