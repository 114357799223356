import { useEffect, useCallback, useState } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
//
import { useAuthContext } from '../hooks';
import { STORAGE_KEY } from '../context/jwt/utils';
import { LOGIN_PATH } from '../../config-global';

// ----------------------------------------------------------------------

const loginPaths: Record<string, string> = {
  jwt: paths.auth.jwt.login,
};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const router = useRouter();
  const { authenticated, method } = useAuthContext();

  const [checked, setChecked] = useState(false);
  const selectBusiness =
    !!localStorage.getItem(STORAGE_KEY.TENANT_BUSINESS_ID) &&
    !!localStorage.getItem(STORAGE_KEY.TENANT_REFRESH_TOKEN) &&
    !!localStorage.getItem(STORAGE_KEY.TENANT_ACCESS_TOKEN);

  const check = useCallback(() => {
    if (!authenticated) {
      const loginPath = loginPaths[method];

      window.location.replace(LOGIN_PATH || loginPath);
    } else {
      if (selectBusiness) router.push(paths.management.root);
      setChecked(true);
    }
  }, [authenticated, method, router, selectBusiness]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}
