import { useEffect, useReducer, useCallback, useMemo } from 'react';
import { IBusinessItem } from 'src/types/business';
import { AuthContext } from './auth-context';
import { STORAGE_KEY, resetTenantSession, setSession, setTenantSession } from './utils';
import { ActionMapType, AuthStateType, AuthUserType } from '../../types';
import { createBusinessTenantSession } from '../../../api/business';
import {
  confirmRegisterUser,
  getBusinessInfo,
  getUserMe,
  reqRegisterUser,
  reqSignIn,
  requestSigninWithAccount,
} from '../../../api/auth';
import { useRouter } from '../../../routes/hooks';
import { paths } from '../../../routes/paths';
import { SIGN_OUT_PATH } from '../../../config-global';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
    business?: IBusinessItem;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
      business: action.payload.business,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const router = useRouter();
  const initialize = useCallback(async () => {
    const accessToken = localStorage.getItem(STORAGE_KEY.USER_ACCESS_TOKEN);
    const refreshToken = localStorage.getItem(STORAGE_KEY.USER_REFRESH_TOKEN);

    const tenantAccessToken = localStorage.getItem(STORAGE_KEY.TENANT_ACCESS_TOKEN);
    const tenantRefreshToken = localStorage.getItem(STORAGE_KEY.TENANT_REFRESH_TOKEN);
    const tenantId = localStorage.getItem(STORAGE_KEY.TENANT_BUSINESS_ID);
    try {
      if (accessToken && refreshToken) {
        setSession(accessToken, refreshToken);

        const response = await getUserMe();

        // exist tenant
        let business: IBusinessItem | undefined;
        if (tenantAccessToken && tenantRefreshToken && tenantId) {
          try {
            setTenantSession(tenantAccessToken, tenantRefreshToken);

            const responseBusiness = await getBusinessInfo();

            const { data } = responseBusiness.data;

            localStorage.setItem(STORAGE_KEY.TENANT_BUSINESS_ID, data.managementBusinessId);

            business = data;
          } catch (error) {
            resetTenantSession();
          }
        }

        const { data: user } = response.data;

        dispatch({
          type: Types.INITIAL,
          payload: {
            user,
            business,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // LOGIN
  const login = useCallback(
    async (username: string, password: string) => {
      const response = await reqSignIn(username, password);
      const { data: resData } = response;

      setSession(resData.accessToken, resData.refreshToken);

      initialize();
    },

    [initialize]
  );

  const accountSignin = useCallback(
    async (idToken: string) => {
      try {
        const response = await requestSigninWithAccount(idToken);

        const { data: resData } = response;

        setSession(resData?.data?.accessToken, resData?.data?.refreshToken);
      } catch (error) {
        router.push(paths.auth.jwt.login);
      } finally {
        initialize();
      }
    },

    [initialize, router]
  );

  // REGISTER
  const register = useCallback(
    async (
      email: string,
      password: string,
      firstName: string,
      lastName: string,
      username: string,
      role: string
    ) => {
      const data = {
        email,
        password,
        firstName,
        lastName,
        username,
        role,
      };

      await reqRegisterUser(data);
    },
    []
  );

  // CONFIRMATION EMAIL
  const confirmRegister = useCallback(
    async (
      managementUserId: string,
      confirmationId: string,
      username: string,
      email: string,
      verifyToken: string
    ) => {
      const data = {
        managementUserId,
        confirmationId,
        username,
        email,
        verifyToken,
      };

      await confirmRegisterUser(data);
    },
    []
  );

  // REGISTER BUSINESS TENANT
  const registerBusinessTenant = useCallback(
    async (managementBusinessId: string) => {
      const res = await createBusinessTenantSession(managementBusinessId);

      const { data: token } = res.data;

      localStorage.setItem(STORAGE_KEY.TENANT_BUSINESS_ID, managementBusinessId);

      setTenantSession(token.accessToken, token.refreshToken);

      initialize();
    },
    [initialize]
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null, null);
    setTenantSession(null);
    window.location.href = SIGN_OUT_PATH || '/';
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      business: state.business,
      //
      login,
      register,
      logout,
      confirmRegister,
      registerBusinessTenant,
      accountSignin,
    }),
    [
      login,
      logout,
      register,
      state.user,
      status,
      confirmRegister,
      state.business,
      registerBusinessTenant,
      accountSignin,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
