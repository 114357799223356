// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  MANAGEMENT: '/management',
};

// ----------------------------------------------------------------------

export const paths = {
  page403: '/403',
  page404: '/404',
  accountSignin: `/sign-in/complete`,
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
    confirmation: `${ROOTS.AUTH}/confirmation/email`,
  },
  // DASHBOARD
  dashboard: {
    root: `${ROOTS.DASHBOARD}/business`,
    business: {
      root: `${ROOTS.DASHBOARD}/business`,
      list: `${ROOTS.DASHBOARD}/business`,
      create: `${ROOTS.DASHBOARD}/business/create`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/business/${id}/edit`,
    },
    analytics: `${ROOTS.DASHBOARD}/analytics`,
  },
  // MANAGEMENT
  management: {
    root: ROOTS.MANAGEMENT,
    employee: `${ROOTS.MANAGEMENT}/employee`,
    details: (id: string) => `${ROOTS.MANAGEMENT}/business/${id}`,
    branch: {
      root: `${ROOTS.MANAGEMENT}/branch`,
      list: `${ROOTS.MANAGEMENT}/branch/list`,
      create: `${ROOTS.MANAGEMENT}/branch/create`,
      details: (id: string) => `${ROOTS.MANAGEMENT}/branch/${id}`,
      edit: (id: string) => `${ROOTS.MANAGEMENT}/branch/${id}/edit`,
      posDevice: (id: string) => `${ROOTS.MANAGEMENT}/branch/${id}/pos-device`,
      table: (id: string) => `${ROOTS.MANAGEMENT}/branch/${id}/table`,
      report: (id: string) => `${ROOTS.MANAGEMENT}/branch/${id}/report`,
      saleSummary: (id: string) => `${ROOTS.MANAGEMENT}/branch/${id}/sale-summary`,
      settings: (id: string) => `${ROOTS.MANAGEMENT}/branch/${id}/settings`,
      account: (id: string) => `${ROOTS.MANAGEMENT}/branch/${id}/account`,
    },
    posDevice: {
      root: `${ROOTS.MANAGEMENT}/pos-device`,
      list: `${ROOTS.MANAGEMENT}/pos-device/list`,
      create: `${ROOTS.MANAGEMENT}/pos-device/create`,
      details: (id: string) => `${ROOTS.MANAGEMENT}/pos-device/${id}`,
      edit: (id: string) => `${ROOTS.MANAGEMENT}/pos-device/${id}/edit`,
      reSignIn: (id: string) => `${ROOTS.MANAGEMENT}/pos-device/${id}/re-sign-in`,
    },
    businessItem: {
      root: `${ROOTS.MANAGEMENT}/business-item`,
      list: `${ROOTS.MANAGEMENT}/business-item/list`,
      create: `${ROOTS.MANAGEMENT}/business-item/create`,
      details: (id: string) => `${ROOTS.MANAGEMENT}/business-item/${id}`,
      edit: (id: string) => `${ROOTS.MANAGEMENT}/business-item/${id}/edit`,
    },
    marketing: {
      root: `${ROOTS.MANAGEMENT}/marketing`,
      campaign: {
        root: `${ROOTS.MANAGEMENT}/marketing`,
        create: `${ROOTS.MANAGEMENT}/marketing/campaign/create`,
        details: (id: string) => `${ROOTS.MANAGEMENT}/marketing/campaign/${id}`,
        edit: (id: string) => `${ROOTS.MANAGEMENT}/marketing/campaign/${id}/edit`,
      },
    },
    category: {
      root: `${ROOTS.MANAGEMENT}/category`,
      list: `${ROOTS.MANAGEMENT}/category/list`,
      create: `${ROOTS.MANAGEMENT}/category/create`,
      details: (id: string) => `${ROOTS.MANAGEMENT}/category/${id}`,
      edit: (id: string) => `${ROOTS.MANAGEMENT}/category/${id}/edit`,
    },
    country: {
      root: `${ROOTS.MANAGEMENT}/country`,
      list: `${ROOTS.MANAGEMENT}/country/list`,
      create: `${ROOTS.MANAGEMENT}/country/create`,
      details: (id: string) => `${ROOTS.MANAGEMENT}/country/${id}`,
      edit: (id: string) => `${ROOTS.MANAGEMENT}/country/${id}/edit`,
    },
    region: {
      root: `${ROOTS.MANAGEMENT}/region`,
      list: `${ROOTS.MANAGEMENT}/region/list`,
      create: `${ROOTS.MANAGEMENT}/region/create`,
      details: (id: string) => `${ROOTS.MANAGEMENT}/region/${id}`,
      edit: (id: string) => `${ROOTS.MANAGEMENT}/region/${id}/edit`,
    },
  },
};
