import originAxios, { AxiosResponse } from 'axios';
import axios, { endpoints } from 'src/utils/axios';
import { IBusinessItemData } from '../types/business';
import { encrypt, EncryptionKeysType } from '../utils/pgp-encryption';
import { HOST_API } from '../config-global';

export async function getUserMe() {
  const URL = endpoints.auth.me;

  const res = await axios.managementInstance.get(URL);
  return res;
}

export async function getBusinessTenantMe() {
  const URL = endpoints.business.tenantMe;

  const res = await axios.businessTenantInstance.get(URL);
  return res;
}

export async function getBusinessInfo(): Promise<AxiosResponse<IBusinessItemData>> {
  const URL = endpoints.business.info;

  const res = await axios.businessTenantInstance.get(URL);
  return res;
}

export async function reqSignIn(username: string, password: string) {
  const encryptionKeys = await requestSignIn();

  const encrypted = await encrypt(encryptionKeys.data.data.pk, { username, password });
  const URL = endpoints.auth.login;

  const res = await axios.managementInstance.post(URL, {
    id: encryptionKeys.data.data.id,
    ct: encrypted,
    pk: encryptionKeys.data.data.pk,
  });

  return res.data;
}

export async function reqRegisterUser(data: {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  username: string;
  role: string;
}) {
  const encryptionKeys = await requestSignUp();

  const encrypted = await encrypt(encryptionKeys.data.data.pk, data);
  const URL = endpoints.auth.register;

  const res = await axios.managementInstance.post(URL, {
    id: encryptionKeys.data.data.id,
    ct: encrypted,
    pk: encryptionKeys.data.data.pk,
  });

  return res.data;
}

export async function confirmRegisterUser(data: {
  managementUserId: string;
  confirmationId: string;
  username: string;
  email: string;
  verifyToken: string;
}) {
  const URL = endpoints.auth.confirmRegister;

  const res = await axios.managementInstance.post(URL, data);
  return res.data;
}

export async function requestSignIn() {
  const URL = `${endpoints.auth.requestSignIn}`;

  const res = await axios.managementInstance.get<{ data: EncryptionKeysType }>(URL);

  return res;
}

export async function requestSignUp() {
  const URL = `${endpoints.auth.requestSignUp}`;

  const res = await axios.managementInstance.get<{ data: EncryptionKeysType }>(URL);

  return res;
}

export async function requestSigninWithAccount(idToken: string) {
  const URL = `${HOST_API}${endpoints.auth.accountSignIn}`;

  const res = await originAxios.get(URL, {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });

  return res;
}
